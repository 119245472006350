<template>
  <div class="bg-gray-300">
    <div class="space-y-4 px-5 py-4 text-xs font-medium lg:text-sm">
      <template v-if="agent.assignedEmployee && agent.assignedEmployee.id">
        <PuSkeleton width="60%">
          <h3
            v-if="!loading.asa"
            class="text-gray-800"
            v-text="asaFullName"
          />
        </PuSkeleton>
        <PuSkeleton width="80%">
          <p
            v-if="!loading.asa"
            class="text-gray-600 mt-1 mb-2 text-xs"
          >
            Account Executive
          </p>
        </PuSkeleton>
        <PuSkeleton width="70%">
          <div
            v-if="!loading.asa"
            :class="linkClass"
          >
            <mail-icon
              :class="iconClass"
            />
            <a
              v-if="asa.email"
              :href="`mailto:${asa.email}`"
              class="text-ia-300 hover:underline"
              @click.prevent
              v-text="asa.email"
            />
            <p v-else>
              No Email Specified
            </p>
          </div>
        </PuSkeleton>
        <PuSkeleton width="40%">
          <div
            v-if="!loading.asa"
            :class="linkClass"
          >
            <phone-icon :class="iconClass" />
            <a
              v-if="asa.phone"
              :href="`tel:${asa.phone}`"
              class="text-ia-300 hover:underline"
              @click.prevent
              v-text="phoneNumber"
            />
            <p v-else>
              No Phone Specified
            </p>
          </div>
        </PuSkeleton>
      </template>
      <div
        v-else
        class="text-center font-bold"
      >
        No ASA Assigned
      </div>
    </div>
    <div class="space-y-4 text-xs font-medium lg:text-sm">
      <template v-if="agent.assignedEmployee && agent.assignedEmployee.id">
        <PuSkeleton width="40%">
          <div
            v-if="!loading.asa"
            class="flex justify-between border-1 border-t-1 border-gray-400 pb-2"
          >
            <a
              href="https://static.idealagent.com/persist/ideal_agent_closing_instructions.pdf"
              target="_blank"
              class="py-2 px-4 underline text-ia-300 cursor-pointer text-center border-r-1 border-gray-400"
              :class="linkClass"
            >
              Closing Instructions <external-link-icon :class="externalLinkClass" />
            </a>
            <a
              href="https://static.idealagent.com/persist/ideal_agent_w9.pdf"
              target="_blank"
              class="py-2 px-5 underline text-ia-300 cursor-pointer text-center"
              :class="linkClass"
            >
              W9 <external-link-icon :class="externalLinkClass" />
            </a>
          </div>
        </PuSkeleton>
      </template>
      <div
        v-else
        class="text-center font-bold"
      >
        No ASA Assigned
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { formatPhoneNumber } from '@/utils/helperFunctions';
import { PhoneIcon, MailIcon } from '@/components/icons';
import { ExternalLinkIcon } from '@vue-hero-icons/solid';

export default {
  name: 'Asa',
  components: {
    PhoneIcon,
    MailIcon,
    ExternalLinkIcon,
    // ExternalPageLinkIcon,
  },
  props: {
    asa: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('agent', ['loading', 'agent']),
    phoneNumber: vm => formatPhoneNumber(vm.asa.phone),
    iconClass: () => 'h-4 mr-1 text-gray-600 fill-current',
    externalLinkClass: () => 'h-4 text-ia-300 fill-current',
    linkClass: () => 'mt-2 flex font-bold text-xs',
    asaFullName() {
      const { firstName, lastName } = this.asa;
      if (!firstName || !lastName) return null;
      return `${firstName} ${lastName}`;
    },
  },
};
</script>
