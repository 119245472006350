// API URLs
export default {
  USER_LOGIN: '/user/login',
  USER_OTP_VALIDATE: 'user/login/otp/validate',
  USER_LOGOUT: '/user/logout',
  USER_DATA: 'user/session',
  USER_RESET_PASSWORD_REQUEST: '/user/generateResetToken',
  USER_RESET_PASSWORD_CHECK_TOKEN: '/user/resetTokenCheck',
  USER_RESET_PASSWORD: '/user/reset',
  ALCF_FORM: '/clientOperations/activeListingConfirmation',
  ICF_FORM: '/clientOperations/contact',
  LAF_FORM: '/clientOperations/listingAppointment',
  PCF_FORM: '/clientOperations/underContract',
  BUF_FORM: '/clientOperations/buyerUpdate',
  IBCF_FORM: '/clientOperations/buyerContact',
  IOCF_FORM: '/clientOperations/offerContract',
  IOVAF_FORM: '/clientOperations/offerVideoAppointment',
  IOSPF_FORM: '/clientOperations/offerSalePurchase',
  IOICF_FORM: '/clientOperations/offerContact',
  IOF_FORM: '/clientOperations/offer',
  PROPERTY_PCS_FIELDS: '/property/pcfFields',
  BUYER_BUF_FIELDS: '/buyer/bufFields',
  SEARCH_FELL_THROUGH: '/clientOperations/searchFellThrough',
  LISTING_FELL_THROUGH: '/clientOperations/listingFellThrough',
  ASSIST_FORM: '/clientOperations/assistWithClient',
  CALCULATION_REFERRAL_FEE_SELLER: propertyId => `/calculation/property/${propertyId}/referralFee`,
  CALCULATION_REFERRAL_FEE_BUYER: buyerProfileId => `/calculation/buyer/${buyerProfileId}/referralFee`,
  CREATE_ACCOUNT: '/user/account/password',
  MAIL_CHECK_LOGIN: '/user/agentPortalCanLogin',
  GET_USERS_TO_IMPERSONATE: '/agent/users',
  GET_IMPERSONATION_TOKEN: '/user/session/impersonation',
  FILE_ATTACHMENT: '/fileAttachment',
  BUYER_BSF_FIELDS: buyerProfileId => `/buyer/${buyerProfileId}/bsfFields`,
  PROPERTY_SPF_FIELDS: propertyId => `/property/${propertyId}/spfFields`,
  PROPERTY_IOSPF_FIELDS: propertyId => `/property/${propertyId}/iospfFields`,
  PROPERTY_HISTORY: (propertyId, agentId) => `/clientHistory/property/${propertyId}/agent/${agentId}`,
  BUYER_HISTORY: (buyerProfileId, agentId) => `/clientHistory/buyerProfile/${buyerProfileId}/agent/${agentId}`,
  BSF_FORM: (agentId, clientId) => `/clientOperations/buyerSold/${agentId}/${clientId}`,
  SPF_FORM: (agentId, clientId) => `/clientOperations/sold/${agentId}/${clientId}`,
  AGENT_BASIC_INFO: agentId => `/agent/${agentId}/basicInfo`,
  AGENT_ASA: agentId => `/agent/${agentId}/asa`,
  AGENT_REFERRAL_AGREEMENT: agentId => `/agent/${agentId}/referralAgreement`,
  DASHBOARD_SUMMARY: agentId => `/agentSummary/dashboard/${agentId}`,
  SECONDARY_AGENTS: agentId => `/agent/${agentId}/secondaryAgents`,
  GENERATE_SELLER_STATEMENT: propertyId => `/statement/referralFeeDue/property/${propertyId}`,
  GENERATE_BUYER_STATEMENT: buyerProfileId => `/statement/referralFeeDue/buyer/${buyerProfileId}`,
  REFERRAL_PROPERTY: (agentId, propertyId) => `/referral/${agentId}/property/${propertyId}`,
  REFERRAL_BUYER_PROFILE: (agentId, buyerId) => `/referral/${agentId}/buyerProfile/${buyerId}`,
  GET_USER_TOURS: userId => `/user/${userId}/tours`,
  REFERRAL_BY_AGENT_ID: agentId => `/referral/${agentId}`,
  DEACTIVATE_TOUR_BY_ID: (userId, tourId) => `/user/${userId}/tours/${tourId}/deactivate`,
  PROPERTY_ATTACHMENTS: propertyId => `/property/${propertyId}/attachments`,
  BUYER_ATTACHMENTS: buyerProfileId => `/buyer/${buyerProfileId}/attachments`,
  PROPERTY_CASH_OFFER: propertyId => `/property/${propertyId}/cashOffer`,
  PROPERTY_OFFER_FIELDS: propertyId => `/property/${propertyId}/offerFields`,
  PROPERTY_REFERRAL_SECONDARY_AGENT: (propertyReferralId, secondaryAgentId) => `/property-referral/${propertyReferralId}/secondary-agent/${secondaryAgentId}`,
  BUYER_PROFILE_REFERRAL_SECONDARY_AGENT: (buyerProfileReferralId, secondaryAgentId) => `/buyer-profile-referral/${buyerProfileReferralId}/secondary-agent/${secondaryAgentId}`,
  UPDATE_AGENT_ATTACHMENT: (agentId, fileType) => `/agent/${agentId}/attachments?fileAttachmentType=${fileType}`,
  AGENT_ATTACHMENT: agentId => `/agent/${agentId}/attachments`,
  LISTINGS_ON_DEMAND: id => `/agent/${id}/listingOnDemandPackage`,

  // Probably outdated endpoints (not used)
  EMAIL_UPDATE: '/agent/profile/email',
  EMAIL_UPDATE_CONFIMATION: '/agent/profile/confirmEmail',
  PHONE_UPDATE: '/notification/phone/generateCode',
  PHONE_UPDATE_CONFIRMATION: '/agent/profile/confirmPhone',

};
