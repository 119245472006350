import { getFile } from '@/api/actions';

export const downloadFileByBlob = (blob, name) => {
  const link = document.createElement('a');
  const url = window.URL.createObjectURL(blob);
  document.body.appendChild(link);

  link.style = 'display: none';
  link.href = url;
  link.download = name;
  link.click();
  window.URL.revokeObjectURL(url);
  link.remove();
};

// only include meme types that you want to present in a new tab here
export const getMimeType = (extension) => {
  const ext = extension[0] === '.' ? extension.replace(/^./, '') : extension;
  if (['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'webp'].includes(ext)) {
    return `image/${ext}`;
  } if (ext === 'pdf') {
    return 'application/pdf';
  } return null;
};

export const openFileInNewTab = async (blob, type) => {
  const file = new Blob([blob], {
    type,
  });
  // IE won't allow opening a Blob with window.open()
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(file);
  } else {
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, '_blank');
  }
};

export const getFileExtensionFromFileName = (fileName) => {
  if (!fileName) return '.txt';
  return fileName.match(/\.[0-9a-z]+$/i)[0];
};

export const getMimeTypeFromFileName = (fileName) => {
  const ext = getFileExtensionFromFileName(fileName);
  return getMimeType(ext);
};

export const presentFile = async (file, fileName) => {
  const isSupportedMimeType = getMimeTypeFromFileName(fileName);
  if (isSupportedMimeType) {
    await openFileInNewTab(file, isSupportedMimeType);
  } else {
    downloadFileByBlob(file, fileName);
  }
};

export const downloadFile = async (fileId, storageName, name) => {
  if (!fileId || !storageName) return;
  const file = await getFile(fileId, storageName);
  await presentFile(file, name);
};
